<template>
  <div>
    <div :class="accessorialsContainer" v-if="!notFound">
      <div class="accessorials-group">
        <div class="accessorials-group__title-container">
          <h6 class="accessorials-group__title" v-if="accessorialsSelected.general.length">
            {{ $t("allOptions.general") }}
          </h6>
        </div>
        <div class="accessorials-group__container-accessorial">
          <div
            v-for="(item, index) in accessorialsSelected.general"
            class="accessorials-group__accessorial-type"
            :key="index"
          >
            <span class="disc-style"> </span>
            <span class="accessorials-group__text">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="accessorials-group">
        <div class="accessorials-group__title-container">
          <h6 class="accessorials-group__title" v-if="accessorialsSelected.pickup.length">
            {{ $t("allOptions.origin") }}
          </h6>
        </div>
        <div class="accessorials-group__container-accessorial">
          <div
            v-for="(item, index) in accessorialsSelected.pickup"
            class="accessorials-group__accessorial-type"
            :key="index"
          >
            <span class="disc-style"> </span>
            <span class="accessorials-group__text">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="accessorials-group">
        <div class="accessorials-group__title-container">
          <h6 class="accessorials-group__title" v-if="accessorialsSelected.delivery.length">
            {{ $t("allOptions.destination") }}
          </h6>
        </div>
        <div class="accessorials-group__container-accessorial">
          <div
            v-for="(item, index) in accessorialsSelected.delivery"
            class="accessorials-group__accessorial-type"
            :key="index"
          >
            <span class="disc-style"> </span>
            <span class="accessorials-group__text">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="accessorials-group__not-found" v-if="notFound">
      <p>{{ $t("myLoads.myLoadsDetail.noAccessoriesFound") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccessorialsByType",
  props: {
    accessorials: Array,
    chosenLoad: Object,
  },
  data() {
    return {
      accessorialsContainer: null,
      notFound: false,
      accessorialsSelected: {
        general: [],
        pickup: [],
        delivery: [],
      },
    };
  },
  created() {
    if (this.$route.name.toLowerCase() === "loadsdetails") {
      this.accessorialsContainer = "accessorials-container";
    }
    if (!this.accessorials.length) {
      this.notFound = true;
    } else {
      this.notFound = false;
      this.filterAccessorialsByType();
    }
  },
  methods: {
    filterAccessorialsByType() {
      for (let i = 0; i < this.accessorials.length; i += 1) {
        // Validate accessorials structure
        const isAccessorials = "accessorials" in this.accessorials
          ? this.accessorials[i].accessorials
          : this.accessorials[i];
        let accessorialName = isAccessorials.name;
        if (accessorialName.toLowerCase() === "hazardous material handling" && this.chosenLoad.line_items[0].hazmat_class_division) {
          accessorialName += ` - ${this.chosenLoad.line_items[0].hazmat_class_division}`;
        }
        switch (isAccessorials.accessorial_type) {
          case "general":
            this.accessorialsSelected.general.push({ ...this.accessorials[i], name: accessorialName });
            break;
          case "delivery":
            this.accessorialsSelected.delivery.push(this.accessorials[i]);
            break;
          default:
            this.accessorialsSelected.pickup.push(this.accessorials[i]);
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accessorials-container {
  padding: 1% 7%;
}

.accessorials-group {
  &__title-container {
    color: $color-border-container;
    text-align: start;
  }
  &__container-accessorial {
    color: $color-primary-company;
    // padding: 1% 0;
    overflow: hidden;
  }
  &__accessorial-type {
    @include font-standart-text;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__text {
    display: flex;
    text-align: left;
    font-weight: normal;
  }
  &__title {
    font-weight: bold;
    margin-bottom: 6px;
    padding-top: 8px;
  }
  &__not-found {
    color: $color-primary-company;
    p {
      margin-bottom: 0;
    }
  }
}

.disc-style {
  background-color: $color-primary-company;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 5px;
}
</style>
